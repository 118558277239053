<template>
  <div>
    <el-dialog v-model="dialogVisible" title="添加分组" width="40%" :close-on-click-modal="false" 
              :close-on-press-escape="false" :show-close="false" >
      <div class="flex">
        <span><span style="color:red">*</span>分&ensp;组&ensp;名 ：</span>
        <el-input v-model="groupName" placeholder="输入分组名" maxlength="20" show-word-limit style="width: 80%" ></el-input>
      </div>
      <div class="flex">
        <span><span style="color:red">*</span>分组描述：</span>
        <el-input v-model="describe" type="textarea" placeholder="输入分组描述" :rows="5" maxlength="100" show-word-limit style="width: 80%;"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel" size="small">取消</el-button>
          <el-button type="primary" @click="editGroup" size="small" >确定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>

 

</template>

<script>
import {editUserGroupInfo } from '../../../api/api'
import until from '../../../until/until'
export default {

  data() {
    return {
      dialogVisible: true,
      managerVisible:false,
      groupData:{},
      groupName: "",
      describe: "",
    };
  },
  methods: {
    getParentData(data) {
      this.groupData = data;
      this.groupName = this.groupData.groupName;
      this.describe = this.groupData.describe;
    },
    editGroup() {
      if (until.isNULL(this.groupName)) {
        this.$message.warning({ message: '请输入组名'});
        return;
      }
      if (until.isNULL(this.describe)) {
        this.$message.warning({ message: '请输入描述'});
        return;
      }
      var param = {
        uuid:this.groupData.uuid,
        describe:this.describe,
        groupName:this.groupName,
      }

      editUserGroupInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '新增成功',  type: 'success'});
          this.cancel();
          this.updateParentView();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })

    },
    choiceManager() {
      this.managerVisible = true;
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },
    updateParentView() {
      this.$emit('update')
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.pagination {
  text-align: center;
  margin: 20px 0px;
}
</style>