<template>
  <div class="page" v-if="mainPage">
    <!--标题-->
    <ls-title title="用户分组"></ls-title>
    <div>
      <!-- 条件框 -->
      <div class="conddiv">
        <el-input v-model="condition" placeholder="搜索分组名" prefix-icon="el-icon-search" style="width: 200px"></el-input>
        <el-button type="warning" style="margin-left: 5px" @click="search">搜索</el-button>
        <el-button type="primary" style="float: right" @click="this.addPage=true">添加分组</el-button>
      </div>

      <!-- 表格 -->
      <el-table :data="tableData" border style="width: 100%; border-radius: 10px"  :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
        <el-table-column type="index" label="序号" width="60" label-class-name="tablehead" align="center " />
        <el-table-column prop="groupName" label="分组名" label-class-name="tablehead" align="center" />
        <el-table-column prop="userNum" label="成员数量" width="160" label-class-name="tablehead" align="center"/>
        <el-table-column prop="describe" label="分组描述" label-class-name="tablehead" align="center" />
        <el-table-column prop="managerNameDesc" label="管理员"  label-class-name="tablehead" align="center" />
        <el-table-column label="操作" label-class-name="tablehead" align="center"  width="180" >
          <template #default="scope">
            <span class="textbtn" @click="edit(scope.row)">编辑</span>
            /
            <span class="textbtn" @click="manage(scope.row)">管理</span>
            /
            <span class="textbtn" @click="user(scope.row)">用户</span>
            /
            <span class="textbtn" @click="deleteGroup(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
     <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div> 
    </div>
  </div>

  <template v-if="this.editPage">
    <GroupEdit ref="childEditView" @update="update" @cancel="this.editPage=false"></GroupEdit>
  </template>
  <template v-if="this.addPage">
    <GroupAdd @update="update" @cancel="this.addPage=false"></GroupAdd>
  </template>
  <template v-if="managerPage">
    <GroupManager ref="childManagerView" @update="update" @cancel="this.managerPage = false"></GroupManager>
  </template>
  <template v-if="userPage">
    <GroupUser ref="childUserView" @update="update" @cancel="this.userPage = false"></GroupUser>
  </template>
</template>

<script>
import {getUserGroupInfos,deleteUserGroupInfo } from '../../../api/api'
import GroupAdd from "./GroupAdd.vue";
import GroupManager from "./GroupManager.vue";
import GroupUser from "./GroupUser.vue";
import GroupEdit from "./GroupEdit.vue";
import session from "../../../store/store";

export default {
  components: {
    GroupAdd,
    GroupManager,
    GroupUser,
    GroupEdit
  },
  data() {
    return {
      emptyText:'加载中',
      mainPage:true,
      addPage: false,
      managerPage: false,
      userPage: false,
      editPage: false,
      condition: "", 
      currentPage: 1,
      size: 10,
      total: 0, //总数
      tableData: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getUserGroupInfos();
    },
    update(){
      this.getUserGroupInfos();
    },
    search() {
      this.getUserGroupInfos();
    },
    getUserGroupInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        page:this.currentPage - 1,
        size:this.size,
        condition:this.condition,
      }
      getUserGroupInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.tableData = res.data.dataInfo;
        }else {
          this.total = 0;
          this.tableData.splice(0, this.tableData.length);
          this.emptyText = "暂无数据"
        }
      })
    },

    edit(row) {
      this.editPage = true;
      setTimeout(() => {
        this.$refs.childEditView.getParentData(row);
      }, 200);
    },

    manage(row) {
      this.managerPage = true;
      setTimeout(() => {
        this.$refs.childManagerView.getParentData(row);
      }, 200);
    },

    user(row) {
      this.userPage = true;
      setTimeout(() => {
        this.$refs.childUserView.getParentData(row);
      }, 200);
    },

    deleteGroup(row) {
      this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var param = {
            unitUuid:session.getLoginInfo().unitUuid,
            uuid:row.uuid,
          }
          deleteUserGroupInfo(param).then(res => {
            if (res.data.code == 200) {
              this.$message({ message: '删除成功',  type: 'success'});
              this.getUserGroupInfos();
            } else {
              this.$message.error({ message: res.data.message});
            }
          })

        }).catch(() => {});
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getUserGroupInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserGroupInfos();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.textbtn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
/* .el-input__prefix {
  top: 5px !important;
} */
.tablehead {
  color: white;
}
</style>