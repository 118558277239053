<template>
  <div>
    <el-dialog v-model="dialogVisible" title="添加分组" width="40%" :close-on-click-modal="false" 
              :close-on-press-escape="false" :show-close="false" >
      <div class="flex">
        <span><span style="color:red">*</span>分&ensp;组&ensp;名 ：</span>
        <el-input v-model="groupName" placeholder="输入分组名" maxlength="20" show-word-limit style="width: 80%" ></el-input>
      </div>
      <div class="flex">
        <span><span style="color:red">*</span>分组描述：</span>
        <el-input v-model="describe" type="textarea" placeholder="输入分组描述" :rows="5" maxlength="100" show-word-limit style="width: 80%;"></el-input>
      </div>
      <!-- <div class="flex" style="display:none">
        <span>管 理 人 ：</span>
        <el-input v-model="user" placeholder="输入管理人" style="width: 80%">
          <template #append>
            <el-button @click="choiceManager" type="primary" style="color: white" >选择管理</el-button>
          </template>
        </el-input>
      </div> -->
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel" size="small">取消</el-button>
          <el-button type="primary" @click="addGroup" size="small" >确定</el-button>
        </span>
      </template>
    </el-dialog>

    <template v-if="managerVisible">
      <GroupNewManager @cancel="this.managerVisible = false"></GroupNewManager>
    </template>
  </div>

 

</template>

<script>
import {addUserGroupInfo } from '../../../api/api'
import session from "../../../store/store";
import until from '../../../until/until'
import GroupNewManager from "./GroupNewManager.vue";
export default {
  components: {
    GroupNewManager
  },
  data() {
    return {
      dialogVisible: true,
      managerVisible:false,
      groupName: "",
      describe: "",
    };
  },
  methods: {
    addGroup() {
      if (until.isNULL(this.groupName)) {
        this.$message.warning({ message: '请输入组名'});
        return;
      }
      if (until.isNULL(this.describe)) {
        this.$message.warning({ message: '请输入描述'});
        return;
      }
      var param = {
        unitUuid:session.getLoginInfo().unitUuid,
        describe:this.describe,
        groupName:this.groupName,
      }

      addUserGroupInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '新增成功',  type: 'success'});
          this.cancel();
          this.updateParentView();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })

    },
    choiceManager() {
      this.managerVisible = true;
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },
    updateParentView() {
      this.$emit('update')
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.pagination {
  text-align: center;
  margin: 20px 0px;
}
</style>