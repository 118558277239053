<template>
  <div>
    <el-dialog v-model="manageVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="60%">
      <template #title>
        <span style="color: white; font-size: 20px">管理人员</span>
        <el-button size="small" style="margin-left: 15px; float: right" @click="addManager" >新增管理员</el-button>
        <el-button size="small" style="margin-left: 5px; float: right" @click="search">搜索</el-button >
        <el-input v-model="condition" placeholder="搜索姓名/电话号码" prefix-icon="el-icon-search" size="small" style="width: 200px; float: right" >
        </el-input>
      </template>

        <!-- 表格 -->
        <el-table @selection-change="handleSelectionChange"  :data="tableData" border style="width: 100%; border-radius: 10px" :empty-text="this.emptyText">
          <el-table-column prop="name" label="姓名" align="center " />
          <el-table-column prop="phone" label="电话号码" align="center " />
          <el-table-column prop="idcard" label="身份证号" align="center " />
          <el-table-column prop="address" label="居住地址" align="center">
            <template #default="scope">
              {{ scope.row.address != "" ? scope.row.address.replace(/#/g, "") : "--"}}
            </template>
          </el-table-column>
          <el-table-column type="selection" width="55" />
        </el-table>

        <div class="pagination">
          <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
        </div> 

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel" size="small">取消</el-button>
          <el-button type="primary" @click="remove" size="small" >删除</el-button>
        </span>
      </template>
    </el-dialog>

    <template v-if="newManageVisible">
      <GroupNewManager ref="childView" @update="update" @cancel="this.newManageVisible = false"></GroupNewManager>
    </template>
  </div>

</template>

<script>
import {getManagerInfosWithExternal,updateGroupManagerInfo } from '../../../api/api'
import GroupNewManager from "./GroupNewManager.vue";
export default {
  components: {
    GroupNewManager
  },
  data() {
    return {
      emptyText:'加载中',
      condition: "",
      manageVisible: true,
      newManageVisible: false,
      multipleSelection: [],
      groupData:{},
      currentPage: 1,
      size: 10,
      total: 0, //总数
      tableData: [],
    };
  },
  methods: {
    getParentData(data) {
      this.groupData = data;
      this.getManagerInfosWithExternal();
    },
    search() {
      this.getManagerInfosWithExternal();
    },
    update() {
      this.getManagerInfosWithExternal();

      setTimeout(() => {
        this.updateParentView();
      }, 1000);
    },
    getManagerInfosWithExternal() {
      let param = {
        unitUuid:this.groupData.unitUuid,
        type:1,
        groupUuid:this.groupData.uuid,
        groupType:1,
        page:this.currentPage - 1,
        size:this.size,
        condition:this.condition,
      }

      getManagerInfosWithExternal(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.tableData = res.data.dataInfo;
        }else {
          this.total = 0;
          this.tableData.splice(0, this.tableData.length);
          this.emptyText = "暂无数据"
        }
      })
    },

    cancel() {
      this.manageVisible = false;
      this.$emit("cancel");
    },

    updateParentView() {
      this.$emit("update");
    },

    remove(){
      if(this.multipleSelection.length == 0) {
        this.$message.warning({ message: '请先选择管理人员'});
        return;
      }
      let param = {
        uuid:this.groupData.uuid,
        type:2,
        managerInfos:JSON.stringify(this.multipleSelection),
      }

      updateGroupManagerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '移除成功',  type: 'success'});
          this.cancel();
          this.updateParentView();
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    addManager(){
      this.newManageVisible = true;
      setTimeout(() => {
        this.$refs.childView.getParentData(this.groupData);
      }, 200);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getManagerInfosWithExternal();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getManagerInfosWithExternal();
    },
  },
};
</script>

<style scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
</style>