<template>
  <div>
    <el-dialog v-model="userVisible" :close-on-click-modal="false"  :close-on-press-escape="false" :show-close="false" width="60%" >
      <template #title>
        <span style="color: white; font-size: 20px">用户列表</span>
        <el-button size="small"  style="margin-left: 15px; float: right"  @click="addUser" >新增用户</el-button >
        <el-button size="small" style="margin-left: 5px; float: right" @click="search"  >搜索</el-button>
        <el-input v-model="condition"  placeholder="搜索姓名/身份证号码" prefix-icon="el-icon-search" size="small" style="width: 200px; float: right">
        </el-input>
      </template>

        <el-table @selection-change="handleSelectionChange" :data="tableData" border style="width: 100%; border-radius: 10px" :empty-text="emptyText">
          <el-table-column prop="name" label="姓名" width="180" align="center " />
          <el-table-column prop="age" label="年龄" width="60" align="center " />
          <el-table-column prop="sex" label="性别" width="60" align="center " />
          <el-table-column prop="idcard" label="身份证号" align="center " />
          <el-table-column prop="address" label="居住地址" align="center ">
            <template #default="scope">
              {{ scope.row.address != "" ? scope.row.address.replace(/#/g, "") : "--"}}
            </template>
          </el-table-column>
          <el-table-column type="selection" width="55" />
        </el-table>

        <div class="pagination">
          <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
        </div> 
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancel" size="small">取消</el-button>
            <el-button type="primary" @click="remove" size="small">删除</el-button>
          </span>
        </template>
    </el-dialog>

    <template v-if="newUserVisible">
      <GroupNewUser ref="childAddUserView" @update="update" @cancel="this.newUserVisible = false"></GroupNewUser>
    </template>
  </div>
</template>

<script>
import {getUserInfosWithExternal,updateGroupUserInfo } from '../../../api/api'
import GroupNewUser from "./GroupNewUser.vue";
export default {
  components: {
    GroupNewUser,
  },
  data() {
    return {
      emptyText:'加载中',
      condition: "",
      userVisible: true,
      newUserVisible: false,
      multipleSelection: [],
      groupData:{},
      currentPage: 1,
      size: 10,
      total: 0, //总数
      tableData: [],
    };
  },
  methods: {
    getParentData(data) {
      this.groupData = data;
      this.getUserInfosWithExternal();
    },

    update() {
      this.getUserInfosWithExternal();

      setTimeout(() => {
        this.updateParentView();
      }, 1000);
    },

    search() {
      this.getUserInfosWithExternal();
    },
    getUserInfosWithExternal() {
      let param = {
        unitUuid:this.groupData.unitUuid,
        type:1,
        groupUuid:this.groupData.uuid,
        groupType:1,
        page:this.currentPage - 1,
        size:this.size,
        condition:this.condition,
      }
      getUserInfosWithExternal(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.tableData = res.data.dataInfo;
        }else {
          this.total = 0;
          this.tableData.splice(0, this.tableData.length);
          this.emptyText = "暂无数据"
        }
      })
    },
    remove(){
      if(this.multipleSelection.length == 0) {
        this.$message.warning({ message: '请先选择用户'});
        return;
      }
      let param = {
        uuid:this.groupData.uuid,
        type:2,
        userInfos:JSON.stringify(this.multipleSelection),
      }

      updateGroupUserInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '移除成功',  type: 'success'});
          this.cancel();
          this.updateParentView();
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    cancel() {
      this.userVisible = false;
      this.$emit("cancel");
    },

    updateParentView() {
      this.$emit("update");
    },
    /**
     * 新增
     */
    addUser(){
      this.newUserVisible = true;
      setTimeout(() => {
        this.$refs.childAddUserView.getParentData(this.groupData);
      }, 200);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
       //分页
    handleSizeChange(val) {
      this.size = val;
      this.getUserInfosWithExternal();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserInfosWithExternal();
    },
  },
};
</script>

<style scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
</style>